<!-- 登录界面 -->
<template>
  <div class="v15_5416">
    <div style="width: 100%; height: 95%">
      <div class="left">
        <div style="width: 100%; height: 5%"></div>
        <img src="@/assets/Login page.png" class="img1" />
      </div>
      <div class="right">
        <div class="up">
          <div class="up_left">
            <img src="@/assets/logo.png" class="img2" />
          </div>
          <div
            style="width: 8%; height: 100%; float: right; display: flex"
          ></div>
          <div class="up_right">
            <span v-if="Lflag == true" class="v15_5738">青昕云</span>
            <span v-else-if="Lflag == false" class="v15_5738"
              >Healthy Cloud</span
            >
          </div>
        </div>
        <div
          style="width: 100%; height: 20%; display: flex; align-items: center"
        >
          <span v-if="Lflag == true" class="login">登录</span>
          <span v-else-if="Lflag == false" class="login">Login</span>
        </div>
        <div class="down">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <div
              style="
                width: 100%;
                height: 2.1505vw;
                display: flex;
                align-items: center;
              "
            >
              <span v-if="Lflag == true" class="v15_5420">用户名</span>
              <span v-else-if="Lflag == false" class="v15_5420">Username</span>
            </div>
            <el-form-item
              style="
                width: 24.1935vw;
                height: 2.1505vw;
                margin-bottom: 1.0753vw;
              "
              prop="username"
            >
              <el-input v-model="ruleForm.username" placeholder=" Account name or Email " ></el-input>
            </el-form-item>
            <div
              style="
                width: 100%;
                height: 2.1505vw;
                display: flex;
                align-items: center;
              "
            >
              <span v-if="Lflag == true" class="v15_5420">密码 </span>
              <span v-else-if="Lflag == false" class="v15_5420">Password </span>
            </div>
            <!-- 新增忘记密码文字按键 -->
            <el-form-item
              style="width: 29.1935vw; margin-bottom: 1.0753vw;display: flex;"
              prop="password"
            >
              <el-input style="width: 24.1935vw ; float: left;" type="password" v-model="ruleForm.password" ></el-input>
              <el-button
                  type="text"
                  @click="forgetpass">
                  <span style="width: 4.2vw;height: auto;font-size: 0.8602vw; margin-left: 0.3vw;float: left;;display: block">
                    forgotten password?
                  </span>
              </el-button>
            </el-form-item>
            <el-form-item style="height: 2.1505vw">
              <el-button
                style="
                  width: 24.1935vw;
                  height: 2.1505vw;
                  padding: 0vw;
                  background-color: rgba(25, 44, 250, 1);
                  border-radius: 0.2688vw;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                type="primary"
               
                @click.native.prevent="handleLogin"
              
                ><span
                  style="
                    font-size: 0.8602vw;
                    height: 2.1505vw;
                    display: flex;
                    align-items: center;
                  "
                  v-if="Lflag == true"
                  >登录</span
                >

                <span
                  style="
                    font-size: 0.8602vw;
                    height: 2.1505vw;
                    display: flex;
                    align-items: center;
                  "
                  v-if="Lflag == false"
                  >Login</span
                >
              </el-button>
              <div
                style="
                  width: 21.5054vw;
                  height: 2.1505vw;
                  display: flex;
                  justify-content: right;
                "
              >
             
                <el-button
                  class="v15_5424"
                  type="text"
                  @click="resetForm('ruleForm')"
                  ><span class="v15_5424" v-if="Lflag == true">重置</span
                  ><span class="v15_5424" v-if="Lflag == false"
                    >Reset</span
                  >
                </el-button>
                
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div
      style="
        text-align: center;
        bottom: 0;
        margin: 0 auto;
        width: 100%;
        height: 5%;
        display: flex;
        color: #5c6b77;
      "
    >
      <a
        target="_blank"
        element.style.textDecoration="none"
        style="
          text-decoration: none;
          color: inherit;
          font-size: 0.6989vw;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 90%;
        "
        href="https://beian.miit.gov.cn"
        >浙ICP备20026509号-3</a
      >
      <div style="width: 5%">
        <el-button
          v-if="Lflag == true"
          class="v15_5424"
          type="text"
          @click="SetLanguage_ZH"
          ><span id="zh" class="v15_5424">ZH</span></el-button
        >
        <el-button
          v-else-if="Lflag == false"
          class="v15_5425"
          type="text"
          @click="SetLanguage_ZH"
          ><span id="zh" class="v15_5425">ZH</span></el-button
        >
      </div>
      <div style="width: 5%; display: flex; justify-content: left">
        <el-button
          v-if="Lflag == true"
          class="v15_5425"
          type="text"
          @click="SetLanguage_EN"
          ><span id="en" class="v15_5425">EN</span></el-button
        >
        <el-button
          v-else-if="Lflag == false"
          class="v15_5424"
          type="text"
          @click="SetLanguage_EN"
          ><span id="en" class="v15_5424">EN</span></el-button
        >
      </div>  
    </div>
    <!-- 账号邮箱验证 -->
    <el-dialog title="Account Email Verification:" :visible="dialogFormVisible1" style="text-align: center;" :show-close=true @close="dialogFormVisible1 = false">
      <el-form :model="form" >
            <el-form-item label="user ID:" label-width="10.3vw" style="width: 30vw;margin-left: 10%;">
                <el-input v-model="form.userID" auto-complete="off" style="width: 90%;margin-left: 0;"></el-input> 
            </el-form-item>
      
            <el-form-item label="Email:" label-width="10.3vw" style="width: 30vw;margin-left: 10%;">
                <el-input v-model="form.EmailID" auto-complete="off" style="width: 90%;"></el-input> 
            </el-form-item>
        
            <el-form-item label="captcha:"   label-width="10.3vw" style="width: 30vw;margin-left: 10%;float: left;">
              <el-input v-model="form.captcha" auto-complete="off" style="width: 90%;"></el-input>
            </el-form-item>
            <el-button type="text"  style="float: left;" @click="countDown" :disabled="! this.canClick">
              {{content}}
            </el-button>
        </el-form>
      
        <div slot="footer" class="dialog-footer" style="width: 100%;display: flex; justify-content: center;margin-top: 5vh;">
          <el-button type="primary" @click="verification" style="padding:1.5vh 5vw;" >verification</el-button> 
        </div>
    </el-dialog>

    <!-- 重置密码 -->
    <el-dialog title="Reset Password" :visible="dialogFormVisible2" style="text-align: center;"  :show-close=true @close="dialogFormVisible2 = false">
      <el-form :model="form" :rules="resetrules" ref="form" >
        <el-form-item >
          <el-form-item label="New Password:"  prop="newpassword" label-width="10.3vw" style="width: 30vw;margin-left: 20%; margin-bottom: 3vh;" >
                <el-input show-password v-model="form.newpassword" auto-complete="off" style="width: 90%;"  type="password" placeholder="8-16 digits and letters" ></el-input> 
          </el-form-item>
          <el-form-item label="Confirm Password:"  prop="confirmpassword" label-width="10.3vw" style="width: 30vw;margin-left: 20%;">
                <el-input show-password v-model="form.confirmpassword"   auto-complete="off" style="width: 90%;" type="password"></el-input> 
          </el-form-item>
        </el-form-item>
      </el-form>
      <div style="width: 30vw; text-align: left;margin-left: 10vw;padding-top: 1vh;">
        <h4 style="width:auto; color: red; "> Please note:</h4>
        <p style="color:red">1、Password length cannot be less than 6 characters</p>
        <p style="color:red">2、Password must consist of numbers, letter</p>
      </div>
      <div slot="footer" class="dialog-footer" style="width: 100%;display: flex; justify-content: center;margin-top: 5vh;">
          <el-button type="primary" @click="respassword" style="padding:1.5vh 5vw;" >Confirm Reset</el-button> 
        </div>
    </el-dialog>
   
  </div> 
</template>

<script>
import { toUserResolution } from "ol/proj";
import {setpassword2, userinfor} from "@/api/data";
import {sendmail_secur} from "@/api/data";
import { verify } from "@/api/data";

export default {
  data() {
    // 是否包含一位数字
    const regNumber = /(?=.*[\d])/;
    // 是否包含一位字母
    const regLetter = /(?=.*[a-zA-Z])/;
    // 是否包含一位特殊字符
    // const regCharacter = /(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、])/
    // 校验新密码
    const validatePass = async  (rule, value, callback) =>{
      value=this.form.newpassword
      if (value === '') {
        callback(new Error('The new password cannot be empty! Please re-enter'))
      } else {
        if (value.length > 16) {
          callback(new Error('Passwords should be no more than 16 characters in length.'))
      } else if (value.length < 6) {
          callback(new Error('Passwords should be no less than 6 characters in length.'))
      } else {
        if (!/^[a-zA-Z\d]{1}/.test(value)) {
          callback(new Error('Passwords must begin with an English letter or number!'))
        } else {
            if (!regNumber.test(value)) {
              callback(new Error('The password must consist of numbers, letters of the alphabet!'))
            } else if (!regLetter.test(value)) {
              callback(new Error('The password must consist of numbers, letters of the alphabet!'))
            } else{
              callback()
            }
          }
        }
      }
    }
	    var validatePass2 = (rule, value, callback) => {
        console.log(this.form)
        value = this.form.confirmpassword
        console.log(value)
	      if (value === "") {
	        callback(new Error("Please enter your password again"));
	      } else if (value !== this.form.newpassword) {
	        callback(new Error("Inconsistent passwords entered twice!"));
	      } else {
	        callback();
	      }
	    };

    return {
      //保存输入的账号密码
      ruleForm: {
        username: "", //用于储存用户输入的用户名
        password: "", //用于储存用户输入的密码
        
      },
      //对账号密码输入的一些限制
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          //{ min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          //{ min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        
      },
      form: {
          userID:'',
          EmailID:'',
          captcha:'',
          newpassword:'',
          confirmpassword:'',
      },
      resetrules:{
        newpassword: [
          { required: true,validator: validatePass , trigger: "blur" },
        
        ],
	      confirmpassword: [
	        { required: true, validator: validatePass2, trigger: "blur" },
	      ],
      },
      Lflag: true,
     
      email1:'',
      totalTime:60,
      content:'Send Email',
      canClick: true,
      dialogFormVisible1:false,
      dialogFormVisible2:false,
      use1:'',

    };
  },
  created: async function () {
    this.$nextTick(() => {
      const bodyStyle = document.body.style; // 获取body样式
      bodyStyle.overflow = "auto"; // 设置溢出内容为隐藏
      const htmlStyle = document.getElementsByTagName("html")[0].style; // 获取html样式
      htmlStyle.overflow = "auto"; // 设置溢出内容为隐藏
    });
    this.Lflag = this.$store.state.Language; //store里的传到该组件
    this.$store.commit("setLanguage", this.Lflag);
    
    if (this.Lflag == true) {
      this.rules = {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          //{ min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          //{ min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      };
    } else {
      this.rules = {
        username: [
          {
            required: true,
            message: "Please enter a username",
            trigger: "blur",
          },
          //{ min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        password: [
          {
            required: true,
            message: "Please enter your password",
            trigger: "blur",
          },
          //{ min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      };
    }
  },
  methods: {
    async handleLogin() {
      await this.huoqu();


      this.$refs.ruleForm.validate((valid) => {
        //验证是否输入为空
        if (valid) {
          //this.loading = true  
          // 发送网络请求，通过dispatch触发action中的login方法发送网络请求
          // 传递参数时的格式需注意：需要调用this.$qs.stringify（），并且参数要用 abc：this.abc 的格式
          this.$store
            .dispatch(
              "login",
              this.$qs.stringify({
                username: this.ruleForm.username,
                password: this.ruleForm.password,
               
              }) //调用actions里的方法时用dispatch，在this.$qs.stringify（）里面包要传给后端的参数
            )
            .then((res) => {
              //console.log(res)//打印返回的参数，用于测验，但是这个地方收不到返回值不知道为什么，不过这个地方不需要返回值
              this.$router.push("/Project_select"); // 跳转到下一页面的路径
              this.$store.commit("username", this.ruleForm.username); //将用户名保存到state和本地
              this.$store.commit("setEmail",this.email1);
              console.log('提交的email是',this.email1)
              console.log('提交的ruleForm=',this.ruleForm.username);
              //this.loading = false
            })
            .catch(() => {
              //this.loading = false
            });
        } else {
          console.log("error submit!!");
          return false;
        }
       
      });
      
    },
    async huoqu() {
      // 调用异步操作，例如发送网络请求
      try {
        const res = await userinfor(this.$qs.stringify({username: this.ruleForm.username}));
        console.log("res=", res);
        console.log(res.data[0].email);
        console.log(res.data[0].username);
        this.ruleForm.username = res.data[0].username;
        this.email1 = res.data[0].email;
      } catch (error) {
        // 异常处理
        console.error("获取用户信息失败：", error);
      }
    },
   
    verification(){
      verify(
        this.$qs.stringify({email:this.form.EmailID,verification:this.form.captcha})
      ).then((res)=>{
        console.log("验证=",res)
        if(res.data == '验证码校验通过'){
          this.dialogFormVisible1 = false;
          this.dialogFormVisible2 = true;
        }else{
          alert('验证码不对')
        }
      }
      )
    },  
    respassword(){
      setpassword2(
        this.$qs.stringify({username:this.form.userID , new_password:this.form.confirmpassword})
      ).then((res)=>{
        console.log('res是：',res);
        if(res.data == 1 ){
          alert('Password changed successfully. Please log in again');
          this.dialogFormVisible2=false
        }
        
      })
    },
    SetLanguage_ZH() {
      this.Lflag = true;
      this.$store.commit("setLanguage", this.Lflag);
    },
    SetLanguage_EN() {
      this.Lflag = false;
      this.$store.commit("setLanguage", this.Lflag);
    },
    //重置输入框
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    forgetpass(){
      this.dialogFormVisible1=true;
    },
    // 发送邮箱计数倒计时
    countDown () {
      
      sendmail_secur(
        this.$qs.stringify({TO:this.form.EmailID,language: 'E' ,username:this.form.userID})
      ).then((res)=>{
        if(res.data == '密码设置成功'){
          alert('Password changed successfully. Please log in again');
        }else if(res.data == '验证码不对'){
          alert('Invalid verification code');
        }
        
         
      })  
      if (!this.canClick) return  //改动的是这两行代码
            this.canClick = false
            this.content = this.totalTime + 's Resend after'
            let clock = window.setInterval(() => {
              this.totalTime--
              this.content = this.totalTime + 's Resend after'
              if (this.totalTime < 0 || this.dialogFormVisible1 == false) {
                window.clearInterval(clock)
                this.content = 'Resend Email'
                this.totalTime = 60
                this.canClick = true  //这里重新开启
              }
            },1000) 
    }
  },
};
</script>
<style scoped>
::v-deep .el-input__inner {
  height: 2.1505vw;
  border-radius: 0.1075vw;
  font-size: 0.6452vw;
}

::v-deep .el-form-item__content {
  line-height: 2.4vw;
}
::v-deep .el-form-item__error {
  font-size: 0.6452vw;
}
</style>
<style>
.img1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img2 {
  width: 3.2258vw;
  height: 2.3118vw;
}
.v15_5416 {
  width: 100%;
  height: 98vh;
  background: rgba(240, 240, 240, 1);
  opacity: 1;
  position: relative;
  top: 0vw;
  left: 0vw;
  /*overflow: hidden;*/
}

.login {
  color: rgb(17, 16, 16);
  position: absolute;
  font-family: Microsoft YaHei UI;
  font-weight: Bold;
  font-size: 1.7204vw;
  opacity: 1;
  text-align: left;
}

.v15_5420 {
  color: rgba(0, 0, 0, 1);
  position: absolute;
  font-family: Microsoft YaHei UI;
  font-weight: Bold;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
  display: flex;
  align-items: center;
}

.v15_5424 {
  color: rgba(25, 44, 250, 1);
  position: absolute;
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}
.v15_5425 {
  color: rgb(17, 16, 16);
  position: absolute;
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}
.v15_5416::after {
  /*清除浮动*/
  content: "";
  display: block;
  clear: both;
}

.left {
  width: 50%;
  height: 100%;
  float: left;
}

.right {
  width: 40%;
  height: 100%;
  float: right;
}

.up_left {
  width: 60%;
  height: 100%;
  float: left;
  display: flex;
  justify-content: right;
  align-items: center;
}

.up_right {
  width: 25%;
  height: 100%;
  float: right;
  display: flex;
  justify-content: left;
  align-items: center;
}

.up::after {
  /*清除浮动*/
  content: "";
  display: block;
  clear: both;
}

.up {
  width: 100%;
  height: 20%;
}

.down {
  width: 100%;
  height: 60%;
}

.v15_5738 {
  color: rgba(25, 44, 250, 1);
  position: absolute;
  font-family: Microsoft YaHei UI;
  font-weight: Bold;
  font-size: 1.7204vw;
  opacity: 1;
  text-align: left;
}
</style>
