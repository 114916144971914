<!-- 项目选择界面 -->
<template>
  <div class="v35_316">
    <div class="v14_4873">
      <div
        class="head_left"
        style="
          width: 4%;
          height: 100%;
          float: left;
          display: flex;
          justify-content: right;
          align-items: center;
        "
      ></div>
      <div
        style="
          width: 3.5%;
          height: 100%;
          float: left;
          display: flex;
          justify-content: left;
          align-items: center;
        "
      >
        <img
          src="@/assets/logo.png"
          style="width: 2.2043vw; height: 1.6129vw"
        />
      </div>
      <div
        class="head_mid"
        style="
          width: 73%;
          height: 100%;
          float: left;
          align-items: center;
          display: flex;
        "
      >
        <span v-if="Lflag == true" class="v14_4874" style="width: 15%"
          >青昕云</span
        >
        <span v-if="Lflag == false" class="v14_4874" style="width: 15%"
          >Healthy Cloud</span
        >
      </div>
      <div style="width: 4%; height: 100%; float: right"></div>
      <div
        class="head_right"
        style="
          width: 12%;
          height: 100%;
          float: right;
          display: flex;
          align-items: center;
        "
      >
        <div
          style="
            width: 80%;
            height: 100%;
            float: right;
            display: flex;
            align-items: center;
            justify-content: right;
          "
        >
          <img
            src="@/assets/用户.png"
            style="width: 1.5054vw; height: 1.6129vw"
          />
        </div>
        <div
          style="
            width: 20%;
            height: 100%;
            float: right;
            display: flex;
            align-items: center;
          "
        >
          <el-button type="text" @click="dialogFormVisible1 = true;" >
            <!-- <span class="v14_4877">{{ this.$store.state.username }}</span> -->
            <span class="v14_4877">User </span>
          </el-button>
          
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 0.5376vw"></div>
    <div class="down1" style="width: 100%; height: 89%">
      <div class="left1" style="background: rgba(255, 255, 255, 1)">
        <div style="width: 25%; height: 90%; float: left"></div>
        <div style="width: 65%; height: 90%; float: left">
          <div style="width: 100%; height: 30%">
            <div style="width: 100%; height: 15%"></div>
            <div style="width: 100%; height: 20%">
              <div
                style="
                  width: 30%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <div class="v35_1032"></div>
              </div>

              <div
                style="
                  width: 60%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <span v-if="Lflag == true" class="v35_1033" style="width: 8%"
                  >项目列表</span
                >
                <span v-if="Lflag == false" class="v35_1033" style="width: 8%"
                  >Project List</span
                >
              </div>
            </div>
          </div>
          <div style="width: 100%; height: 40%"></div>
          <div style="width: 100%; height: 30%">
            <div
              style="
                width: 35%;
                height: 100%;
                justify-content: left;
                align-items: center;
                float: left;
                display: flex;
              "
            >
              <el-button type="text" @click="logout">
                <img
                  src="@/assets/登出.png"
                  style="width: 2.1505vw; height: 2.1505vw"
              /></el-button>
            </div>
            <div
              style="width: 5%; height: 100%; float: left; display: flex"
            ></div>
            <div
              style="
                width: 60%;
                height: 95%;
                justify-content: left;
                align-items: center;
                float: right;
                display: flex;
              "
            >
              <el-button type="text" @click="logout"
                ><span v-if="Lflag == true" class="v35_1026">登出</span>
                <span v-if="Lflag == false" class="v35_1026"
                  >Log Out</span
                ></el-button
              >
            </div>
            <div
              style="width: 60%; height: 5%; float: left; display: flex"
            ></div>
          </div>
        </div>
        <div style="width: 10%; height: 90%; float: left"></div>
        <div
          style="
            text-align: center;
            bottom: 0;
            margin: 0 auto;
            width: 100%;
            height: 10%;
            color: #5c6b77;
          "
        >
          <a
            target="_blank"
            element.style.textDecoration="none"
            style="text-decoration: none; color: inherit; font-size: 0.6989vw"
            href="https://beian.miit.gov.cn"
            >浙ICP备20026509号-3</a
          >
        </div>
      </div>

      <div class="right1" style="background: rgba(255, 255, 255, 1)">
        <div
          style="
            width: 0.5376vw;
            height: 100%;
            display: flex;
            float: left;
            background: rgba(240, 240, 240, 1);
          "
        ></div>
        <div style="width: 6%; height: 100%; float: left"></div>
        <div style="width: 93%; height: 100%; float: left">
          <div style="width: 100%; height: 4.5%"></div>
          <div style="width: 100%; height: 5.5%; align-items: center">
            <span v-if="Lflag == true" class="v35_1047" style="width: 10%"
              >项目列表</span
            >
            <span v-if="Lflag == false" class="v35_1047" style="width: 10%"
              >Project List</span
            >
          </div>
          <div style="width: 100%; height: 2%"></div>
          <div style="width: 100%; height: 80%; overflow: auto">
            <el-container
              v-for="item in project"
              :key="item.id"
              style="
                width: 96.2%;
                height: 40%;
                display: block;
                border-radius: 0.5376vw;
              "
            >
              <div style="width: 100%; height: 90%; border-radius: 0.5376vw">
                <el-header
                  style="
                    width: 100%;
                    background: rgba(244, 249, 255, 1);
                    height: 25%;
                    border-top-right-radius: 0.5376vw;
                    border-top-left-radius: 0.5376vw;
                    padding-left: 2.6882vw;
                  "
                >
                  <div
                    style="
                      width: 100%;
                      height: 100%;
                      display: flex;
                      padding-right: 0vw;
                    "
                  >
                    <div class="v35_1039" style="width: 75%; float: left">
                      {{ item.project }}
                    </div>
                  </div>
                </el-header>
                <div
                  style="
                    width: 100%;
                    background: rgba(244, 249, 255, 1);
                    padding: 0;
                    height: 75%;
                    border-bottom-left-radius: 0.5376vw;
                    border-bottom-right-radius: 0.5376vw;
                  "
                >
                  <div style="width: 100%; height: 100%">
                    <div style="width: 100%; height: 55%">
                      <div
                        class="v35_1040"
                        style="
                          width: 100%;
                          height: 100%;
                          padding-left: 2.6882vw;
                        "
                      >
                        {{ item.information }}
                      </div>
                    </div>
                    <div
                      v-if="Lflag == true"
                      style="
                        display: flex;
                        align-items: center;
                        font-size: 0.8602vw;
                        padding-left: 2.6882vw;
                        height: 15%;
                      "
                    >
                      设备当前状态：
                      <div
                        :class="
                          item.equipment_Status == 0
                            ? 'status-success'
                            : item.equipment_Status == 1
                            ? 'status-warning'
                            : item.equipment_Status == 2
                            ? 'status-serious'
                            : item.equipment_Status == 3
                            ? 'status-cutContact'
                            : ''
                        "
                        
                      ></div>
                      <div>
                        {{
                          item.equipment_Status == 0
                            ? "设备正常"
                            : item.equipment_Status == 1
                            ? "设备报警"
                            : item.equipment_Status == 2
                            ? "设备严重报警"
                            : item.equipment_Status == 3
                            ? "设备断联"
                            : ""
                        }}
                      </div>
                    </div>
                    <div
                      v-else-if="Lflag == false"
                      style="
                        display: flex;
                        align-items: center;
                        font-size: 0.8602vw;
                        padding-left: 2.6882vw;
                        height: 15%;
                      "
                    >
                      Current status of the device:
                      <div
                        :class="
                          item.equipment_Status == 0
                            ? 'status-success'
                            : item.equipment_Status == 1
                            ? 'status-warning'
                            : item.equipment_Status == 2
                            ? 'status-serious'
                            : item.equipment_Status == 3
                            ? 'status-cutContact'
                            : ''
                        "
                        style="margin-left: 1vw;"
                      ></div>
                      <div>
                        {{
                          item.equipment_Status == 0
                            ? "Device normal"
                            : item.equipment_Status == 1
                            ? "Device alarms"
                            : item.equipment_Status == 2
                            ? "Device seriously alarmed"
                            : item.equipment_Status == 3
                            ? "Device disconnected"
                            : ""
                        }}
                      </div>
                    </div>
                    <div style="height: 20%; display: flex">
                      <div style="width: 2.6882vw"></div>
                      <div
                        style="
                          width: 33%;
                          float: left;
                          align-items: center;
                          display: flex;
                          font-size: 0.8602vw;
                        "
                      >
                        Report Time : {{ item.report_time }}
                      </div>
                      <div style="width: 45%; height: 100%; float: left"></div>
                      <div
                        style="
                          width: 20%;
                          height: 100%;
                          float: left;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <el-button
                          v-if="Lflag == true"
                          type="button"
                          style="
                            color: #fff;
                            font-family: Microsoft YaHei UI;
                            font-weight: Regular;
                            font-size: 0.8602vw;
                            background-color: rgba(25, 44, 250, 1);
                            padding-left: 2.0968vw;
                            padding-right: 2.0968vw;
                            padding-top: 0.6989vw;
                            padding-bottom: 0.6989vw;
                            border-radius: 0.2688vw;
                          "
                          @click="
                            setSelectedProjectId(item.report_time, item.project)
                          "
                          >进入</el-button
                        ><el-button
                          v-if="Lflag == false"
                          type="button"
                          style="
                            color: #fff;
                            font-family: Microsoft YaHei UI;
                            font-weight: Regular;
                            font-size: 0.8602vw;
                            background-color: rgba(25, 44, 250, 1);
                            padding-left: 2.0968vw;
                            padding-right: 2.0968vw;
                            padding-top: 0.6989vw;
                            padding-bottom: 0.6989vw;
                            border-radius: 0.2688vw;
                          "
                          @click="
                            setSelectedProjectId(item.report_time, item.project)
                          "
                          >Enter</el-button
                        >
                        <!--点击按钮时调用方法，作用是传递这个项目id-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                  width: 100%;
                  height: 10%;
                  background: rgba(255, 255, 255);
                "
              ></div>
            </el-container>
          </div>
        </div>
      </div>
       <!-- 账户信息 -->
       <el-dialog title="Account Information" :visible="dialogFormVisible1" style="text-align: center;" :show-close=true @close="dialogFormVisible1 = false">
        <el-form   ref="form" >
          <el-form-item >
            <el-form-item label="Account:"  label-width="10vw" style="width: 30vw;margin-left: 13%;margin-bottom:3.4vh" >
                  <el-input  v-model="username" auto-complete="off" style="width: 90%;"  type="text" disabled='disabled' ></el-input> 
            </el-form-item>
            <el-form-item label="Email:"  label-width="10vw" style="width: 30vw;margin-left: 13%;">
                  <el-input    v-model="maskedEmail" auto-complete="off" style="width: 90%;" disabled='disabled'></el-input> 
            </el-form-item>
          </el-form-item>
        </el-form>
      
        <div slot="footer" class="dialog-footer" style="width: 100%;display: flex; justify-content: center;">
          <el-button @click="dialogFormVisible1=false;dialogFormVisible2=true" style="margin-right: 2vw" :disabled="isDisable">Modify Password</el-button>
          <el-button  @click="btsetemail" style="margin-left: 3vw;"> Setting up the mailbox</el-button>
        </div>  
      </el-dialog>

      <!-- 修改密码 -->
      <el-dialog title="Change your password:" :visible="dialogFormVisible2" style="text-align: center;" :show-close=true @close="dialogFormVisible2 = false" >
        <el-form :model="form" :rules="resetrules" ref="form" >
              <el-form-item label="New Password:" prop="newpassword" label-width="12.59vw" style="width: 29.7vw;margin-left: 13.2%;margin-bottom: 4vh;">
                  <el-input v-model="form.newpassword" auto-complete="off" show-password type="password"></el-input> 
              </el-form-item>
         
          
              <el-form-item label="Confirm New Password:" prop="confirmpassword"  label-width="12.59vw" style="width: 29.7vw;margin-left: 13.2%;margin-bottom: 4vh;">
                  <el-input v-model="form.confrimpassword" auto-complete="off" show-password type="password"></el-input> 
              </el-form-item>
        
          
              <el-form-item label="Captcha:"   label-width="12.59vw" style="width: 29.7vw;margin-left: 13.2%;float: left;margin-bottom: 4vh;">
                <el-input v-model="form.passcaptcha" auto-complete="off" ></el-input>
              </el-form-item>
              <el-button type="text"  style="float: left;" @click="countDown2" :disabled="! this.canClick2">
                {{contenttwo}}
              </el-button>
        </el-form>       
          <div slot="footer" class="dialog-footer" style="width: 100%;display: flex; justify-content: center;margin-top: 5vh;">
            <el-button type="primary" @click="changepass" style="padding:1.5vh 5vw;" >verification</el-button> 
          </div>
      </el-dialog>

      <!-- 当前邮箱验证 -->
      <el-dialog title="Current Email Verification:" :visible="dialogFormVisible3" style="text-align: center;" :show-close=true @close="dialogFormVisible3 = false" >
          <el-form :model="form" >
              <el-form-item label="Captcha:"   label-width="8vw" style="width: 28vw;margin-left: 20%;float: left;">
                <el-input v-model="form.captcha" auto-complete="off" ></el-input>
              </el-form-item>
              <el-button type="text"  style="float: left;" @click="countDown" :disabled="! this.canClick">
                {{content}}
              </el-button>
          </el-form>       
          <div slot="footer" class="dialog-footer" style="width: 100%;display: flex; justify-content: center;margin-top: 5vh;">
            <el-button type="primary" @click="verification " style="padding:1.5vh 5vw;" >verification</el-button> 
          </div>
      </el-dialog>

      <!-- 修改邮箱 -->
      <el-dialog title="Modify Email:" :visible="dialogFormVisible4" style="text-align: center;" :show-close=true @close="dialogFormVisible4 = false">
          <el-form :model="form" >
              <el-form-item label="New Email:" label-width="8vw" style="width: 28vw;margin-left: 20%;">
                  <el-input v-model="form.newemail" auto-complete="off" ></el-input> 
              </el-form-item>
          
              <el-form-item label="Captcha:"   label-width="8vw" style="width: 28vw;margin-left: 20%;float: left;">
                <el-input v-model="form.newcaptcha" auto-complete="off" ></el-input>
              </el-form-item>
              <el-button type="text" style="float: left;" @click="countDown1" :disabled="! this.canClick1">
                {{ contentnew }}
              </el-button>
          </el-form>       
          <div slot="footer" class="dialog-footer" style="width: 100%;display: flex; justify-content: center;margin-top: 5vh;">
            <el-button type="primary" @click="confemail" style="padding:1.5vh 5vw;" >Confirmation of changes </el-button> 
          </div>
      </el-dialog>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { getProjects } from "@/api/project"; //将getProjects从/api/project导入，作用是发送网络请求获得项目列表
import { checkToken } from "@/api/user"; //checkToken从/api/user导入，作用是发送网络请求让后端检查token
import { ifemail } from "@/api/data";
import { sendmail_secur } from "@/api/data";
import { verify } from "@/api/data";
import { sendmail } from "@/api/data";
import { saveemail } from "@/api/data";
import { changepassword } from "@/api/data";
export default {
  data() {
    // 是否包含一位数字
    const regNumber = /(?=.*[\d])/;
    // 是否包含一位字母
    const regLetter = /(?=.*[a-zA-Z])/;
    // 是否包含一位特殊字符
    // const regCharacter = /(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、])/
    // 校验新密码
    const validatePass = async  (rule, value, callback) =>{
      value=this.form.newpassword
      if (value === '') {
        callback(new Error('Password cannot be empty!Please re-enter'))
      } else {
        if (value.length > 16) {
          callback(new Error('Passwords should be no more than 16 characters in length.'))
      } else if (value.length < 6) {
          callback(new Error('Passwords should be no less than 6 characters in length.'))
      } else {
        if (!/^[a-zA-Z\d]{1}/.test(value)) {
          callback(new Error('Passwords must begin with an English letter or number!'))
        } else {
            if (!regNumber.test(value)) {
              callback(new Error('The password must consist of numbers, letters of the alphabet!'))
            } else if (!regLetter.test(value)) {
              callback(new Error('The password must consist of numbers, letters of the alphabet!'))
            } else{
              callback()
            }
          }
        }
      }
    }
    var validatePass2 = (rule, value, callback) => {
        console.log(this.form)
        value = this.form.confrimpassword
        console.log(value)
        if (value === "") {
	        callback(new Error("Please enter your password again"));
	      } else if (value !== this.form.newpassword) {
	        callback(new Error("Inconsistent passwords entered twice!"));
	      } else {
	        callback();
	      }
	    };
    return {
      project: [], //用于接收项目列表
      username: "", //用于接收用户名
      token: "", //用于接收token
      buttonClickCount: 0, //用于记录按钮点击次数
      loginCheck: false, //记录登录状态
      _gap_time: 0,
      _beforeUnload_time: 0,
      Lflag: true,
   
      username:this.$store.state.username,
      localemail:this.$store.state.email,
      totalTime:60,
      totalTime1:60,
      totalTime2:60,
      content:'Send Email',
      contentnew:'Send Email',
      contenttwo:'Send Email',
      canClick: true,
      canClick1: true,
      canClick2: true,
      Currentpassword:'',
      newpassword:'',
      confrimpassword:'',
      captcha:'',
      dialogFormVisible1:false,
      dialogFormVisible2:false,
      dialogFormVisible3:false,
      dialogFormVisible4:false,
      isDisable:false,

      form:{
        email:'',
        currentpassword:'',
        newpassword:'',
        confrimpassword:'',
        captcha:'',
        newemail:'',
        newcaptcha:'',
        passcaptcha:'',
      },
      resetrules:{
        newpassword: [
          { required: true,validator: validatePass , trigger: "blur" },
        
        ],
	      confirmpassword: [
	        { required: true, validator: validatePass2, trigger: "blur" },
	      ],
      },
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "margin:0;");
  },
  computed: {
    maskedEmail() {
      console.log(this.localemail);
      if (!this.localemail) {
        this.isDisable = true;
        return   'Unbound email' 
      }
      const parts = this.localemail.split('@');
      const localPart = parts[0];
      const domainPart = parts[1];
      // 隐藏本地部分的中间字符，只显示首尾各一个字符
      const maskedLocalPart = localPart.slice(0, 3) + '*'.repeat(localPart.length - 4) + localPart.slice(-2);

      this.isDisable = false;
      return  `${maskedLocalPart}@${domainPart}` 
    }
  },
  methods: {
    setSelectedProjectId(report_time, project) {
      //该方法在点击进入项目的按钮时使用，作用是将项目的id记录下来用于其他界面发送网络请求时候使用
      console.log(project); //打印，用于测试
      console.log(report_time);
      this.$store.commit("project", project); //将选择的项目名保存到store和本地
      this.$store.commit("settime", report_time); //将选择的项目名保存到store和本地
      this.buttonClickCount++; //按钮计数加一
      this.$router.push("/Main"); //页面跳转
    },
    logout() {
      this.buttonClickCount++; //按钮计数加一
      this.$store.dispatch("logout"); //登出
    },
    beforeunloadHandler() {
      this._beforeUnload_time = new Date().getTime();
    },
    unloadHandler(e) {
      this._gap_time = new Date().getTime() - this._beforeUnload_time;
      debugger;
      //判断是窗口关闭还是刷新
      if (this._gap_time <= 5) {
        //如果是登录状态，关闭窗口前，移除用户
        this.$store.dispatch("logout"); //登出
      }
    },
    btsetemail(){
      ifemail(
        this.$qs.stringify({username :this.username })
      ).then((res)=>{
        console.log('res===',res)
        console.log(res.data)
        if(res.data != ''){
          this.dialogFormVisible1 = false;
          this.dialogFormVisible3 = true;
        }else{
          this.dialogFormVisible1 = false;
          this.dialogFormVisible4 = true;
        }
      })
    },
    verification(){
      verify(
        this.$qs.stringify({email:this.localemail,verification:this.form.captcha})
      ).then((res)=>{
        console.log("验证=",res)
        if(res.data == '验证码校验通过'){
          this.dialogFormVisible3 = false;
          this.dialogFormVisible4 = true;
        }else{
          alert('验证码不对')
        }
      }
      )
    }, 
    countDown () {
      sendmail_secur(
        this.$qs.stringify({TO:this.localemail,language: 'E' ,username:this.username})
      ).then((res)=>{
        console.log(this.localemail)
      }) ;  
      if (!this.canClick) return  //改动的是这两行代码
      this.canClick = false
      this.content = this.totalTime + 's Resend after'
      let clock = window.setInterval(() => {
        this.totalTime--
        this.content = this.totalTime + 's Resend after'
        if (this.totalTime < 0 || this.dialogFormVisible3 ==false) {
          window.clearInterval(clock)
            this.content = 'Resend Email'
            this.totalTime = 60
            this.canClick = true  //这里重新开启
        }
      },1000) 
    },
    countDown1 () {
      sendmail(
        this.$qs.stringify({TO:this.form.newemail,language: 'E' })
      ).then((res)=>{
          console.log('res==',res)
      });
      if (!this.canClick1) return
        this.canClick1 = false
        this.contentnew = this.totalTime1 + 's Resend after'
        let clock1 = window.setInterval(() => {
          this.totalTime1--
          this.contentnew = this.totalTime1 + 's Resend after'
          if (this.totalTime1 < 0 || this.dialogFormVisible4 == false) {
            window.clearInterval(clock1)
              this.contentnew = 'Resend Email'
              this.totalTime1 = 60
              this.canClick1 = true  //这里重新开启
          }
        },1000)    
    },
    countDown2 () {
      sendmail_secur(
        this.$qs.stringify({TO:this.localemail,language: 'E' ,username:this.username})
      ).then((res)=>{
        console.log('res=====',res)
      }) ; 
      if (!this.canClick2) return
        this.canClick2 = false
        this.contenttwo = this.totalTime2 + 's Resend after'
        var clock2 = window.setInterval(() => {
          this.totalTime2--
          this.contenttwo = this.totalTime2 + 's Resend after'
          if (this.totalTime2 < 0 || this.dialogFormVisible2 == false ) {
            window.clearInterval(clock2)
              this.contenttwo = 'Resend Email'
              this.totalTime2 = 60
              this.canClick2 = true  //这里重新开启
          }
        },1000)    
    },
    confemail(){
      saveemail(
        this.$qs.stringify({email:this.form.newemail,verification:this.form.newcaptcha,username:this.username})
      ).then((res)=>{
        if(res.data == '一个邮箱只能绑定一个账号'){
          alert('This email address is already in use')
        }
        console.log('修改邮箱返回=',res)
        if(res.data == '验证码校验通过'){
          alert('Email successfully updated')
          this.localemail=this.form.newemail;
          this.$store.commit("setEmail",this.form.newemail);
          this.dialogFormVisible4 = false;
          this.dialogFormVisible1 = true;
        }
      })
    },
    changepass(){
      changepassword(
        this.$qs.stringify({email:this.localemail,verification:this.form.passcaptcha,new_password:this.form.confrimpassword})
      ).then((res)=>{
        console.log('修改密码=',res)
        if(res.data == '密码设置成功'){
          alert('Password changed successfully. Please log in again')
          this.dialogFormVisible2 = false;
          this.logout();
        }else if(res.data == '验证码不对'){
          alert('Invalid verification code');
        }
      })
    }
  },
  created: async function () {
    this.$store.commit("restoretoken"); //将roken从本地传到store
    checkToken(this.$qs.stringify({ token: this.$store.state.token })).then(
      (res) => {
        //让后端检查token
        if (res != null) {
          //如果后端返回不为空则为登陆成功
          this.loginCheck = true;
        }
        console.log(res);
        console.log(this.$store.state.token);
        console.log('state====' , this.$store.state);
        console.log('email====' , this.$store.state.email)
      }
    );
    this.$store.commit("restorename"); //将保存在本地的用户名传到store
    this.username = this.$store.state.username; //将store里的用户名传到该组件

    this.Lflag = this.$store.state.Language; //store里的传到该组件
    console.log(this.Lflag);

    getProjects(this.$qs.stringify({ username: this.username })).then((res) => {
      //发送网络请求获取项目列表
      this.project = res.data;
      console.log(res.data);
      console.log(this.username);
      console.log();
    });
    this.$nextTick(() => {
      const bodyStyle = document.body.style; // 获取body样式
      bodyStyle.overflow = "auto"; // 设置溢出内容为隐藏
      const htmlStyle = document.getElementsByTagName("html")[0].style; // 获取html样式
      htmlStyle.overflow = "auto"; // 设置溢出内容为隐藏
    });
  },
  beforeRouteLeave(to, from, next) {
    //路由守卫：用于防止用户通过修改路径来随意跳转导致报错，当通过修改路径离开本页面时会触发
    if (this.buttonClickCount < 1 && this.loginCheck == true) {
      //当此时为登陆状态并且没有点击任何一个按钮，将不能跳转出本界面
      next(false);
    } else {
      next(); // 允许导航离开
    }
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    window.addEventListener("unload", (e) => this.unloadHandler(e));
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
    window.removeEventListener("unload", (e) => this.unloadHandler(e));
  },
};
</script>

<style >
.el-button--button {
  width: 6.0215vw;
  height: 2.3656vw;
  color: #fff;
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}
.img {
  display: flex;
  justify-content: right;
  align-items: center;
}

.left1 {
  width: 16%;
  height: 99%;
  float: left;
}

.right1 {
  width: 84%;
  height: 99%;
  float: left;
  display: flex;
}

.down1::after {
  /*清除浮动*/
  content: "";
  display: block;
  clear: both;
}

.v35_1032 {
  width: 0.8065vw;
  height: 0.8065vw;
  background: rgba(25, 44, 250, 1);
  opacity: 1;
  position: absolute;
  border-radius: 50%;
}

.v35_1047 {
  width: 6.8817vw;
  color: rgba(25, 44, 250, 1);
  position: absolute;
  font-family: Microsoft YaHei UI;
  font-weight: Bold;
  font-size: 1.6129vw;
  opacity: 1;
  text-align: left;
}

.v35_1026 {
  width: 1.7204vw;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v35_1037 {
  background: rgba(244, 249, 255, 1);
  opacity: 1;
  position: absolute;
  overflow: hidden;
}

.v35_1044 {
  color: rgba(255, 255, 255, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v35_1039 {
  color: rgba(0, 0, 0, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Bold;
  font-size: 1.0753vw;
  opacity: 1;
  text-align: left;
  display: flex;
  justify-content: left;
}

.v35_1040 {
  color: rgba(0, 0, 0, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v35_1033 {
  width: 5.1613vw;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 1.0753vw;
  opacity: 1;
  text-align: left;
}

.v35_316 {
  width: 100%;
  height: 100vh;
  background: rgba(240, 240, 240, 1);
  opacity: 1;
  position: relative;
  top: 0vw;
  left: 0vw;
  overflow: hidden;
}

.v14_4873::after {
  /*清除浮动*/
  content: "";
  display: block;
  clear: both;
}

.v14_4877 {
  width: 2.4%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: right;
}

.v14_4873 {
  width: 100%;
  height: 10%;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: relative;
  overflow: hidden;
}

.v14_4874 {
  width: 5.1613vw;
  color: rgba(25, 44, 250, 1);
  position: absolute;
  font-family: Microsoft YaHei UI;
  font-weight: Bold;
  font-size: 1.7204vw;
  opacity: 1;
  text-align: left;
}

.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 3.2258vw;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 10.7527vw;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  line-height: 8.6022vw;
}

body > .el-container {
  margin-bottom: 2.1505vw;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 13.9785vw;
}

.el-container:nth-child(7) .el-aside {
  line-height: 17.2043vw;
}

.right-align {
  float: right;
  height: 2.1505vw;
  margin-top: 0.5376vw;
}

.logout {
  height: 1.6129vw;
}

.classRow {
  width: 25%;
  display: flex;
}

.classCenter {
  width: 50%;
  box-flex: 5;
  -webkit-box-flex: 5;
  -moz-box-flex: 5;
  -webkit-flex: 5;
  -ms-flex: 5;
  flex: 5;

  display: flex;
  justify-content: center;
}

.classRight {
  width: 25%;
  box-flex: 1;
  /*灵活度*/
  -webkit-box-flex: 1;
  /* Safari and Chrome */
  -moz-box-flex: 1;
  /* Firefox */
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;

  display: flex;
  justify-content: flex-end;
}
.status-success {
  height: 0.9375rem;
  width: 0.9375rem;
  margin-right: 0.5rem;
  background-color: lime;
  border-radius: 100%;
}
.status-warning {
  height: 0.9375rem;
  width: 0.9375rem;
  margin-right: 0.5rem;
  background-color: yellow;
  border-radius: 100%;
}
.status-serious {
  height: 0.9375rem;
  width: 0.9375rem;
  margin-right: 0.5rem;
  background-color: red;
  border-radius: 100%;
}
.status-cutContact {
  height: 0.9375rem;
  width: 0.9375rem;
  margin-right: 0.5rem;
  background-color: darkgrey;
  border-radius: 100%;
}
.status-connectErr {
  height: 0.9375rem;
  width: 0.9375rem;
  margin-right: 0.5rem;
  background-color: wheat;
  border-radius: 100%;
}
.tips {
  margin-right: 1.25rem;
  display: flex;
  align-items: center;
}


.syMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.topTip {
  text-align: center;
  line-height: 30px;
  color: red;
  font-weight: 700;
  margin: 10px 0;
}

.tip {
  color: red;
  margin-top: 20px;
}

.tip p {
  margin: 5px;
}

.ImgTip {
  text-align: center;
  margin: 0 auto;
}

.ImgTip p {
  margin: 20px 0;
}

</style>