import Vue from 'vue'
import Vuex from 'vuex'
import {login} from '@/api/user'
import router from '@/router' 


Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    project: null,//用来储存项目名
    token:null,//用来储存token
    username:null,//用来储存用户名
    isLogin: false,//用来记录登陆状态
    time: null,
    Language:true,
    email:null,
  },
  mutations: {
    setLanguage(state,data) {
      state.Language = data;
      localStorage.setItem('Language', JSON.stringify(data));
    },
    restoLanguage(state) {
      const storedLanguage = localStorage.getItem('Language');
      state.Language = storedLanguage ? JSON.parse(storedLanguage) : null;
      //console.log(state.username1)
    },
    settime(state,data) {
      state.time = data;
      localStorage.setItem('time', JSON.stringify(data));
    },
    // 存储邮箱数据到localStorage和Vuex的state
    setEmail(state, data) {
      state.email = data;
      localStorage.setItem('email', JSON.stringify(data));
    },
  
    restoreEmail(state) {
      const storedEmail = localStorage.getItem('email');
      if (storedEmail) {
        try {
          state.email = JSON.parse(storedEmail);
        } catch (error) {
          console.error('Error parsing stored email:', error);
        }
      } else {
        state.email = null;
      }
    },
    
    // 存储数据到localStorage和Vuex的state
    username(state, data) {
      state.username = data;
      localStorage.setItem('username', JSON.stringify(data));
      //console.log(state.username1)
    },
    // 从localStorage恢复数据到Vuex的state
    restorename(state) {
      const storedName = localStorage.getItem('username');
      state.username = storedName ? JSON.parse(storedName) : null;
      //console.log(state.username1)
    },
    // 存储数据到localStorage和Vuex的state
    project(state, data) {
      state.project = data;
      localStorage.setItem('project', JSON.stringify(data));
      //console.log(state.username1)
    },
    // 从localStorage恢复数据到Vuex的state
    restoreproject(state) {
      const storedProject = localStorage.getItem('project');
      state.project = storedProject ? JSON.parse(storedProject) : null;
      //console.log(state.username1)
    },
    // 存储数据到localStorage和Vuex的state
    setToken(state, data) {
      state.token = data;
      localStorage.setItem('token', JSON.stringify(data));
      console.log(state.token)
    },
    // 从localStorage恢复数据到Vuex的state
    restoretoken(state) {
      const storedToken = localStorage.getItem('token');
      state.token = storedToken ? JSON.parse(storedToken) : null;
      //console.log(state.username1)
    },
    // 将state里的token清除
    clearToken(state) {
      state.token = null;
      //console.log(state.token)
    },
    // 将state里的用户名清除
    clearUsername(state) {
      state.username = null;
      //console.log(state.username)
    },
    // 将state里的项目名清除
    clearProject(state) {
      state.project = null;
      //console.log(state.project)
    },
    clearEmail(state){
      state.email=null;
    },
    //储存数据到store和本地
    isLogin(state, data) {
      state.isLogin = data;
      localStorage.setItem('isLogin', JSON.stringify(data));
      //console.log(state.username1)
    },
    // 从localStorage恢复数据到Vuex的state
    restoreIslogin(state) {
      const storedIslogin = localStorage.getItem('isLogin');
      state.isLogin = storedIslogin ? JSON.parse(storedIslogin) : null;
      //console.log(state.username1)
    },
    //将login设为真
    isLoginTrue(state){
      state.isLogin = true
    },
    //将login设为假
    isLoginFalse(state){
      state.isLogin = false
    }
    
  },
  actions:{
    //发送登录请求
    login({ commit }, userInfo) {
        //const {} = userInfo
        return new Promise((resolve, reject) => {
          login(userInfo).then(response => {//在这里也会收到返回值
            const { data } = response
            if(data.data.token){//如果有token，就把token保存
              commit('setToken', data.data.token)
              //setToken(data.data.token)
            }
            else{
              //console.log(data.token)
              console.log('没有收到token')
            }
            //console.log(this.state.token)
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
    },
    //登出
    logout({ commit }) {
      commit('clearToken');//在store清除token
      commit('clearUsername');//在store清除用户名
      commit('clearProject');//在store清除项目名
      commit('clearEmail');
      localStorage.removeItem('email');
      localStorage.removeItem('token');//在本地清除token
      localStorage.removeItem('username');//在本地清除用户名
      localStorage.removeItem('project');//在本地清除项目名
      //router.push({ path: '/' }, { replace: true })
      router.push('/Login');//页面跳转到登录界面
    },
    //清除项目名，专用于main界面下“返回项目选择界面”的按钮
    reselectProject({ commit }) {
      commit('clearProject');
      localStorage.removeItem('project');
    },
  },
  // 在创建 store 时恢复 email 数据
  plugins: [store => {
    store.commit('restoreEmail');
    store.commit('restorename')
  }]
})

export default store