<template>
  <div id="app">
    <!--<router-link to="/Project_select">选择项目</router-link>
    <router-link to="/Login">登录</router-link>-->

    <router-view />

    <!--有这个标签才可以让对应路由的界面渲染出来-->
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style></style>
