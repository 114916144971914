import axios from 'axios'
import router from '@/router' 
import store from '@/store'
import { Message } from "element-ui";


// create an axios instance
const service = axios.create({
  // baseURL: 'http://www.htcloud.info:8088',
  baseURL: 'http://121.40.95.239:8088',
  //baseURL: 'http://localhost:8088',
    //baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(//请求拦截，再发送请求前拦截
    // config => {
    //   // do something before request is sent
        
    //     if (store.state.token) {
    //     // let each request carry token
    //     // ['X-Token'] is a custom headers key
    //     // please modify it according to the actual situation
    //     config.headers['X-Token'] = store.state.token
    //     }
    //   return config
    // },
    // error => {
    //   // do something with request error
    //   console.log(error) // for debug
    //   return Promise.reject(error)
    // }
)
  

// response interceptor
service.interceptors.response.use(//响应拦截，在收到返回值后拦截
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
    */
  
    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
      const res = response
      
      // 801代表登陆状态有问题.
      if (res.data.code === 801) {
        // Message({
        //   message: res.message || 'Error',
        //   type: 'error',
        //   duration: 5 * 1000
        // })
        console.log(res.data)
        
        //801: 密码错误或者token有问题（无token或token失效或token被改）
        if (res.data.code === 801 ) {
          // to re-login
          store.commit('isLoginFalse');//修改登陆状态为失效
          Message({
            showClose: true,
            message: "用户名或密码错误",
            center: true,
            type: "error",
            customClass: "customclass",
          });
          //弹窗提示
          router.push({ path: '/' }, { replace: true })//返回登录界面，这里的路由用法有所不同，后面加了个{ replace: true }，因为在本页跳转到本页时候一般情况会报错，加了这个不会报错了
          //router.push('/');
            // store.dispatch('user/resetToken').then(() => {
            //   location.reload()
            // })
          
        }
        // return Promise.reject(new Error(res.message || 'Error'))

        //801: 密码错误或者token有问题（无token，token失效，token被改）
        // if (res.data.data.code === 801 ) {
        //   // to re-login
        //   alert('Please login')
        //   router.push('/Login');
        //   // store.dispatch('user/resetToken').then(() => {
        //   //   location.reload()
        //   // })
          
        // }

        
      } else {//登陆状态正常时候，将返回值继续传递
        //this.$store.commit('isLoginTrue');
        return res
      }
    },
  error => {
    Message({
      showClose: true,
      message: error.response.data.error,
      center: true,
      type: "error",
      customClass: "customclass",
     });
    
      console.log('err' + error) // for debug
      return Promise.reject(error)
    }
  )
  
  export default service
  