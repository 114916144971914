import Vue from 'vue'
import App from './App.vue'
import router from './router' 
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import AFTableColumn from "af-table-column";
//import './Mock/index'  //不用mock的时候需要在这里注释掉
import qs from 'qs'
import store from './store';
import Plugin from 'v-fit-columns';
Vue.use(Plugin);
Vue.use(AFTableColumn);
Vue.prototype.$qs = qs
//axios.defaults.baseURL = "http://localhost:8080"
Vue.prototype.$http = axios;

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  render: h => h(App),
  router:router,
  store:store
}).$mount('#app')
