import request from '@/utils/request'

//发送网络请求：发送登录请求
export function login(data) {
    return request({
      url: '/login',
      method: 'post',
      data
    })
  }
//发送网络请求：检查token，这条指令在每次跳转页面的时候最先发送，等待检查完token后再进行接下来的指令
export function checkToken(data) {
  return request({
    url: '/check',
    method: 'post',
    data
  })
}